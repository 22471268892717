<template>
  <div class="listCon">
    <div class="title">
      {{ typeName }}
      <div class="search">
        <!-- @submit.native.prevent 处理回车搜索时，路由多问号的问题 -->
        <el-form :model="searchForm" ref="searchForm" @submit.native.prevent>
          <el-row>
            <el-col :span="8">
              <el-form-item label="日期" prop="date" label-width="80px">
                <el-date-picker
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  v-model="searchForm.date"
                  type="date"
                  placeholder="请选择"
                  size="small"
                  @change="fetchData"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="要素" prop="factor" label-width="80px">
                <el-select
                  size="small"
                  style="width: 100%"
                  v-model="searchForm.factor"
                  placeholder="请选择"
                  @change="fetchData"
                >
                  <el-option
                    v-for="item in factorOptions"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="深度 /（高度）"
                prop="depth"
                label-width="120px"
              >
                <el-select
                  size="small"
                  style="width: 100%"
                  v-model="searchForm.depth"
                  placeholder="请选择"
                  @change="fetchData"
                >
                  <el-option
                    v-for="item in depthOptions"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>

    <div class="timeBar">
      <div class="btnBg">
        <div class="btn" @click="setSelectTimeIndex(selectTimeIndex - 1)">
          <img src="../../assets/forecast/prev.png" />
        </div>
        <div class="btn" @click="play">
          <img v-if="!timer" src="../../assets/forecast/on.png" />
          <img v-else src="../../assets/forecast/stop.png" />
        </div>
        <div class="btn" @click="setSelectTimeIndex(selectTimeIndex + 1)">
          <img src="../../assets/forecast/next.png" />
        </div>
      </div>
      <div class="timeItem">
        <div
          class="timeText"
          :class="{ active: index == selectTimeIndex }"
          v-for="(item, index) in timeList"
          @click="setSelectTimeIndex(index)"
          :key="index"
        >
          {{ item }}
          <span></span>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="detailCon">
      <img
        v-if="
          baseData?.oceanForecastImageList?.length > 0 &&
          baseData?.oceanForecastImageList[0]?.httpAccessUrl
        "
        :src="baseData?.oceanForecastImageList[0]?.httpAccessUrl"
      />
      <p v-else>暂无数据</p>

      <!-- <img :src="`https://www.iocean.cn/attachment/oceanAppFile/normal/OCEAN_FORCAST/${searchForm.factor
        }/${searchForm.date}/P_southchinasea_${searchForm.factor}${searchForm.factor == 'wind' ? '10m' : ''
        }${searchForm.factor == 'mwp' ? '' : '@'}${searchForm.date
        }T${selectTime}0000.png`" width="90%" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  props: ["dataType", "typeName"],
  data() {
    return {
      searchForm: {
        date: "",
        factor: "",
        depth: "",
      },
      baseData: [],
      factorOptions: [],
      depthOptions: [],
      timeList: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      selectTime: "00",
      selectTimeIndex: 0,
      timer: null,
      forecastDepthLevel:[]
    };
  },
  watch: {
    dataType: function () {
      this.fetchDictionary();
      this.fetchData();
      this.getOceanForecastDepthLevel()
    },
    selectTimeIndex: function () {
      this.fetchData();
    },
    searchForm:{
      handler(newVal, oldVal) {
				if(newVal) {
          this.fetchData();
          this.getOceanForecastDepthLevel()
				}
			},
			deep:true, // 深度监听
			immediate:true, // 初始化监听
    }
  },
  computed: {
    dateStr: function () {},
  },
  mounted() {
    this.searchForm.date = this.getNowDate();
    // this.fetchDeep();
    this.fetchDictionary();
    this.fetchData();
    this.setSelectTimeIndex(0);
    
  },
  methods: {
    getOceanForecastDepthLevel(){
      if(!this.dataType) return;
      if(!this.searchForm.factor) return;
      this.$request("/oceanForecast/getOceanForecastDepthLevel", {
        method: "post",
        data: { 
          forecastTypeId: this.dataType,
          forecastElementId:this.searchForm.factor
         },
      }).then((res) => {
        if(res.errorCode == 0){
          this.depthOptions = res.data.map(item=>{
           return {
            dictKey:item.key,
            dictValue:item.value
           }
          })
          try {
          this.searchForm.depth = res.data[0].key;
        } catch (error) {
          this.searchForm.depth = "";
        }
        }
      });
    },
    fetchDeep() {
      this.$request("/dictionary/getDictionariesByType", {
        method: "post",
        data: { dictType: "depth_level" },
      }).then((res) => {
        this.depthOptions = res.data.list;
        try {
          this.searchForm.depth = res.data.list[0].dictKey;
        } catch (error) {
          this.searchForm.depth = "";
        }
      });
    },
    fetchDictionary() {
      if (!this.dataType) return;
      this.$request("/oceanForecastElement/getOceanForecastElementDictionary", {
        method: "post",
        data: { forecastTypeId: this.dataType },
      }).then((res) => {
        this.factorOptions = res.data.list;
        try {
          this.searchForm.factor = res.data.list[0].dictKey;
        } catch (error) {
          this.searchForm.factor = "";
        }
      });
    },
    setSelectTimeIndex(index) {
      if (index >= this.timeList.length) {
        index = 0;
      }
      if (index < 0) {
        index = this.timeList.length - 1;
      }
      this.selectTimeIndex = index;
      this.selectTime = this.timeList[index];
    },
    fetchData() {
      this.baseData = null;
      if (!this.dataType) return;
      if (!this.searchForm.factor) return;
      if (!this.searchForm.date) return;
      if (!this.searchForm.depth) return;
      let params = {
        forecastElementId: this.searchForm.factor,
        forecastTime: this.searchForm.date + " " + this.selectTime + ":00:00",
        depthLevel: this.searchForm.depth,
        forecastTypeId: this.dataType,
      };
      
      this.$request("/oceanForecast/getOceanForecastInfo", {
        method: "post",
        data: params,
      }).then((res) => {
        if (res.errorCode == 0) {
          this.baseData = null;
          this.baseData = res.data;
        }
      });
    },
    /**
     * 查询当天日期
     */
    getNowDate() {
      const timeOne = new Date();
      const year = timeOne.getFullYear();
      let month = timeOne.getMonth() + 1;
      let day = timeOne.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      const NOW_MONTHS_AGO = `${year}-${month}-${day}`;
      return NOW_MONTHS_AGO;
    },
    play() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      } else {
        this.run();
      }
    },
    run() {
      this.timer = setInterval(() => {
        this.setSelectTimeIndex(this.selectTimeIndex + 1);
      }, 2000);
    },
  },
};
</script>

<style lang="less" scoped>
.listCon {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding-top: 20px;

  .title {
    display: flex;
    line-height: 45px;
    font-size: 18px;
    font-weight: 600;
    color: #1c5dc4;
    padding: 0 20px 0 20px;
    margin-bottom: 10px;
    background: url("../../assets/forecast/line.png") bottom no-repeat;
  }

  .search {
    padding-right: 20px;
    flex: 1;

    .handle {
      padding-top: 5px;
    }

    /deep/ .el-range-separator {
      padding: 0;
    }
  }

  .timeBar {
    display: flex;
    justify-content: flex-start;
    height: 33px;
    padding: 0 0 0 5px;
    margin: 0 20px;
    background: url("../../assets/forecast/timerShaft.png") no-repeat;
    background-size: 100% 30px;

    .btnBg {
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 22px;
      height: 20px;
      margin-top: 5px;

      .btn {
        border: 1px solid #92bada;
        padding: 2px 10px;
        background-color: #92bada;
        color: #fff;
        cursor: pointer;

        &:hover {
          border: 1px solid #76a5cc;
          background-color: #76a5cc;
        }

        > img {
          display: block;
        }
      }

      .btn:nth-child(2) {
        border-radius: 0;
        margin: 0 1px;
      }
    }

    .timeItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;

      .timeText {
        height: 100%;
        line-height: 30px;
        cursor: pointer;
        font-size: 14px;
        color: #fff;
        position: relative;
        width: 32px;
        padding-left: 7px;

        > span {
          display: inline-block;
          width: 2px;
          height: 7px;
          background: rgba(255, 255, 255, 0.5);
          position: absolute;
          bottom: 2px;
          left: 50%;
        }

        &:hover {
          color: #fff;
          font-weight: 600;
          background: url("../../assets/forecast/active.png") no-repeat center;
          background-size: 36px 34px;

          > span {
            background: transparent;
          }
        }
      }

      .active {
        color: #fff;
        font-weight: 600;
        background: url("../../assets/forecast/active.png") no-repeat center;
        background-size: 36px 34px;

        > span {
          background: transparent;
        }
      }
    }
  }

  .detailCon {
    display: flex;
    justify-content: center;
    min-height: 500px;
    padding: 0 20px 20px 20px;

    > img {
      width: 100%;
    }
  }
}
</style>