<template>
    <div>
        <!-- <div class="banner"><img src="../../assets/forecast/banner.jpg" height="310"/></div> -->
        <div class="content">
            <div class="left">
                <LeftMenu v-if="activeMenu" :activeMenu="activeMenu" :hideSerial="hideSerial" :toggleMenu="toggle" :titleIconUrl="titleIconUrl" titleName="海洋预报"
                    :subMenuData="menuList" @handleSelect="handleSelect"/>
            </div>
            <div class="right">
                <List :dataType="dataType" :typeName="typeName" />
            </div>
        </div>
    </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu";
import List from './list.vue'
import { menuLevelList } from '@/mockData'
export default {
    name: "Forecast",
    components: {
        LeftMenu,
        List
    },
    data() {
        return {
            hideSerial: true,
            toggle: true,
            menuList: [],
            dataType: '',
            typeName: '',
            titleIconUrl: require("../../assets/basedata/icon_basedata.png"),
            activeMenu:'',
        }
    },
    mounted() {
        this.menuList = menuLevelList
        this.$request("/oceanForecastType/getOceanForecastTypeTree", {
            method: "post",
            data: {}
        }).then(res => {
            this.menuList = changeTreeData(res.data)
            try {
                let item = this.menuList[0].children[0]
                this.activeMenu = item.id
                this.handleSelect(item.id)
            } catch (error) {}
        })
    },
    methods: {
        handleSelect(data){
            this.dataType = data
            this.menuList.map((item,index)=>{
                if(item.id == data){
                    this.typeName = item.name
                }
                if(item.children.length>0){
                    item.children.map((subItem,subIndex)=>{
                        if(subItem.id == data){
                            this.typeName = subItem.name
                        }
                    })
                }
            })
        }
    }

}
const changeTreeData = (list) => {
    return list.map((item, index) => {
        return {
            ...item,
            title: item.name,
            key: item.id,
            index: item.id,
            children: item.children ? changeTreeData(item.children) : null
        }
    })
}
</script>

<style lang="less" scoped>
.banner{
    width:100%;
    position: absolute;
    left:0;
}
.content {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0px 0 30px 0;
    >.left {
        width: 200px;
        margin-right: 20px;
    }

    >.right {
        width: calc(100% - 200px)
    }
}
</style>